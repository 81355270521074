.anouncementModalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  padding: 20px;
}

.innerModal {
  position: relative;
  max-width: 100%;
  width: 600px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.innerModal .imageContainer {
  width: 100%;
  max-height: 300px;
  overflow: hidden;
  border-radius: 10px;
}

.innerModal .imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.innerModal .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.innerModal .content .title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.innerModal .closeBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 1.3rem;
  background-color: white;
  color: #000;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
