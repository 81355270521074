/* Mobile First Design */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
/* Variables */
:root {
  --primaryColor: #f5cc19;
  --mainTextColor: #023047;
  --mainTabColor: #9d9e9f;
  --activeTabColor: #0d855d;
  --howToColor: #0d855d;
  --warningColor: #ff0000;
}

.container {
  display: flex;
  font-family: 'Montserrat', sans-serif;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background-color: var(--primaryColor);
  color: var(--mainTextColor);
}

/* Header Container */
.headerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: 20px;
  font-size: 2rem;
  font-weight: bold;
  color: #fffbfb;
}

.headerContainer img {
  width: 120px;
  margin-right: 10px;
}

/* Menu Tabs */
.menuTabContainer {
  display: flex;
  width: 100%;
  height: 50px;
  color: #fff;
}

.menuTab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 1.3rem;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: var(--mainTabColor);
}

.menuTab.active {
  background-color: var(--activeTabColor);
}

/* Tabs */

/* Anouncement */
.anouncementContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  background-color: var(--howToColor);
  color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  text-align: center;
}

.closeAnouncement {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
  top: 5%;
  right: 5%;
}

.warningInfoText {
  font-size: 1.2rem;
  font-weight: bold;
}
