/* Variables */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}
.dishTitle {
  font-weight: bold;
  width: 80%;
}

.underTitleOptions {
  font-size: 1.2rem;
  width: 70%;
  margin: 0 auto;
  font-weight: bold;
}

.guide {
  font-size: 1.2rem;
  text-align: center;
  color: var(--howToColor);
}

.warning {
  font-size: 1.1rem;
  text-align: center;
  color: var(--warningColor);
  width: 80%;
  margin: 0 auto;
}

.menuItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.9rem;
  gap: 10px;
  font-weight: 500;
  width: 100%;
  height: 100%;
  padding: 20px;
  text-align: center;
}

.optionContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 1.5rem;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  font-size: 1.5rem;
  font-weight: normal;
}

.optionTitle {
  font-weight: bold;
  font-size: 1.7rem;
}

.item {
  display: flex;
  flex-direction: column;
  font-size: 1.3rem;

  margin: 0 auto;
}

.itemSubtitle {
  width: 80%;
  margin: 0 auto;
  font-size: 1rem;
}
