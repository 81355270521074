.category {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
  overflow: hidden;
  height: 220px;
}

/* Add overlay on top of category to make bottom part of the container bit dark */
.category::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  top: 0;
  left: 0;
}

.category img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.category p {
  position: absolute;
  bottom: 0;
  color: white;
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
}

.category p::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(93, 93, 93, 0.1);
  top: 0;
  left: 0;
}

/* Mobile */
@media (max-width: 768px) {
  .category p {
    font-size: 1.1rem;
  }
}
