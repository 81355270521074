.detailContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
  padding: 15px;
  gap: 7px;
  min-height: 75px;
}

.title {
  font-size: 1rem;
  font-weight: 600;
}

.topBorder {
  border-top: 1px solid #f0f0f0;
}
.itemDetails {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.subtitle {
  font-size: 0.8rem;
  color: #666;
}

.price {
  display: flex;
  justify-content: flex-end;
  font-size: 0.9rem;
}
