.detailsContainer {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
}

.categoryTitle {
  font-size: 1rem;
  font-weight: 500;
  padding: 15px;
  border-bottom: 2px solid #b4b3b3;
}

.categoryTitle span {
  background-color: rgb(12, 81, 14);
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
}
