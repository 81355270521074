.footerContainer {
  display: flex;
  flex-direction: column;
  background-color: rgb(12, 81, 14);
  color: white;
  padding: 20px;
  gap: 20px;
}

.socialMediaContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.socialMediaIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
  font-size: 1.8rem;
}

.copyrightContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
  text-align: center;
  font-size: 0.8rem;
}
