.headerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: rgb(12, 81, 14);
  min-height: 70px;
  color: white;
  position: relative;
}

.backButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 10px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: rgb(12, 81, 14);
}

.headerContainer img {
  width: 75px;
  margin-right: 10px;
  cursor: pointer;
}
