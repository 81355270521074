.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.menuItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  gap: 10px;
  font-weight: 500;
  width: 100%;
  height: 100%;
  padding: 20px;
  text-align: center;
}
