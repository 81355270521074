/* Desktop */
.categoryContainer {
  /* use grid and make it 3 items per row */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  padding: 10px;
  max-width: 90%;
  margin: 20px auto;
}

/* Mobile */
@media (max-width: 768px) {
  .categoryContainer {
    grid-template-columns: repeat(2, 1fr);
    max-width: 100%;
  }
}
