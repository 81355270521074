.container {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  animation: heartbeat 5s infinite;
}

.logo img {
  width: 100%;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
