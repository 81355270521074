@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

.container {
  display: flex;
  flex-direction: column;
  font-family: 'Nunito Sans', sans-serif;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.logo {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.logo img {
  width: 100%;
  height: 100%;
}

.cta {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cta span {
  font-size: 24px;
  color: #333;
}

.cta button {
  padding: 10px 20px;
  background-color: #1dbf73;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Nunito Sans', sans-serif;
}
