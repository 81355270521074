.container {
  display: flex;
  flex-direction: column;
  font-family: 'Nunito Sans', sans-serif;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 50px;
  gap: 20px;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  max-width: 100%;
  width: 500px0;
}

.imageContainer img {
  width: 100%;
  height: auto;
}

.ctaContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 300px;
}

.ctaContainer span {
  text-align: center;
  font-size: 24px;
  color: #333;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
}

.ctaContainer button {
  padding: 10px 20px;
  background-color: #1dbf73;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Nunito Sans', sans-serif;
}
